import React, { } from "react";
import { graphql } from 'gatsby';
import { 
   Box, 
   Link, 
   Center,
   Wrap,
   WrapItem,
   HStack,
   Icon,
   Tag,
   SimpleGrid,
   Spacer,
   AspectRatio,
   Image
} from "@chakra-ui/react"
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { AiOutlineApartment,AiFillTags,AiOutlineFieldTime } from "react-icons/ai"
import { BsArrowCounterclockwise } from "react-icons/bs"
import { GrUserManager } from "react-icons/gr"
import { Link as GatsbyLink } from 'gatsby'
import Layout from '../components/layout.js';
import Parser from "html-react-parser"
import {
   Midasi2,
   TableOfContent
} from '../util/functions.js';
import { getSrc } from 'gatsby-plugin-image'
import "@wordpress/block-library/build-style/style.css"
import "@wordpress/block-library/build-style/theme.css"

const BlogIndex = ({data, path, pageContext: { nextPostUrl, previousPostUrl }}) => {
   
   const post = data.post;
   const title = post.title ?? "" 
   let content = post.content ?? "" 
   
   // const uri = post.uri;
   const date = post.date;
   const datemodi = post.modified;
   const excerpt = post.excerpt;
   const categories = post.categories.nodes;
   const tags = post.tags.nodes;
   const author = post.author.node;
   const icatch = post.featuredImage ? post.featuredImage.node.localFile.childImageSharp : undefined;

   content = content.replace(
      /<iframe/g,
      "<div class='iframe-wrap'><iframe"
   );
   content = content.replace(
      /<\/iframe>/g,
      "</iframe></div>"
   );
   var newContent = [];
   // if(Array.isArray(Parser(content,{ trim: true }))){
   //    Parser(content,{ trim: true }).forEach( (con) => {
   //          newContent.push(con);
   //    })
   // }else{
   //    newContent.push(Parser(content,{ trim: true }));
   // }
   let customTOC = false;
   if(Array.isArray(Parser(content,{ trim: true }))){
      let firstH2 = 0;
      
      Parser(content,{ trim: true }).forEach( (con) => {
         if(con.type === "h2"){
            // console.log(con)
            firstH2++;
         }
         if(firstH2 == 1){
            firstH2 = 999999;
            customTOC = true;
            newContent.push(
               <>
               <Box m="1rem"></Box>
               <TableOfContent toc={post.toc} />
               {con}
               </>
            );
         }else{
            newContent.push(con);
         }
         
      })
   }else{
      newContent.push(Parser(content,{ trim: true }));
   }
   // console.log(post.featuredImage ? post.featuredImage.node.localFile.childImageSharp.fluid.src : "");
   // console.log(path);/feature-3721
   ////////////////////////////////////////////
   ///////////////////////////////////////////

   return (
      <>
      <Layout 
         title={title}
         description={excerpt
            .replace("[toc]","")
            .replace("[holl_befor_sentence]","")
            .replace("[pachi_befor_sentence]","")}
         image={icatch ? getSrc(icatch) : undefined}
         uri={path}
      >
      {/* <Layout uri={path}> */}
            <Midasi2>{title}</Midasi2>
            <Box>
               <Center>
                  <Wrap spacing={5}>
                     <WrapItem>
                        <HStack>
                           <Icon as={AiOutlineFieldTime} />
                           <Box　
                              textTransform="uppercase" 
                              fontSize="sm" 
                              letterSpacing="wide">
                                 {date}に公開
                           </Box>
                        </HStack>
                     </WrapItem>
                     {date !== datemodi ? 
                     <WrapItem>
                        <HStack>
                           <Icon as={BsArrowCounterclockwise} />
                           <Box　
                              textTransform="uppercase" 
                              fontSize="sm" 
                              letterSpacing="wide">
                                 {datemodi}に更新
                           </Box>
                        </HStack>
                     </WrapItem>
                     : ""}
                  </Wrap>
               </Center>
               <Center m={2}>
                  <Wrap spacing={5}>
                     <WrapItem>
                        <HStack>
                        <Icon as={AiOutlineApartment} />
                        {categories &&
                           React.Children.toArray(categories.map((cat,index) => {
                              return (
                                 <>
                                 <Link
                                    as={GatsbyLink}
                                    to={"../category/" + cat.slug +"/1"}
                                    mt={1}
                                    display="block"
                                    fontSize="lg"
                                    lineHeight="normal"
                                    fontWeight="semibold"
                                    href="#"
                                 >
                                    <Tag
                                       fontWeight="bold" 
                                       textTransform="uppercase" 
                                       fontSize="sm" 
                                       letterSpacing="wide"
                                    >
                                       {cat.name}
                                    </Tag>
                                 </Link>
                                 </>
                              )
                           }))
                        }
                        </HStack>
                     </WrapItem>
                     {tags.length !== 0 ?
                     <WrapItem>
                        <HStack>
                        <Icon as={AiFillTags} />
                        {tags &&
                           tags.map((tag,index)=> {
                              return (
                                 <>
                                 <Link
                                    as={GatsbyLink}
                                    to={"../tag/" + tag.slug +"/1"}
                                    mt={1}
                                    display="block"
                                    fontSize="lg"
                                    lineHeight="normal"
                                    fontWeight="semibold"
                                    href="#"
                                 >
                                    <Tag
                                       fontWeight="bold" 
                                       textTransform="uppercase" 
                                       fontSize="sm" 
                                       letterSpacing="wide"
                                    >
                                       {tag.name}
                                    </Tag>
                                 </Link>
                                 </>
                              )
                           })
                        }
                        </HStack>
                     </WrapItem>
                     : ""}
                     {author.name !== "admin" ? 
                     <WrapItem>
                        <HStack>
                           <Icon as={GrUserManager} />
                           <Box　
                              textTransform="uppercase" 
                              fontSize="sm" 
                              letterSpacing="wide">
                                 {author.name}
                           </Box>
                        </HStack>
                     </WrapItem>
                     : ""}
                  </Wrap>
               </Center>
            </Box>

            

            <Box bg="#fff" mx="0.75rem" p="1rem" className="wordpress" fontSize="1rem">
               {customTOC == false ? <TableOfContent toc={post.toc} />: ""}
               {newContent}
            </Box>

            <SimpleGrid columns={2} my="0.5rem" mx="0.75rem" spacing={"1rem"}>
               <Box bg="#fcfcfc" p="0.5rem" fontSize={"0.8rem"}>
                  {data.previous && 
                     <>
                     <AspectRatio w="100%" ratio={16 / 9}>
                        {
                           data.previous.featuredImage?
                              <GatsbyImage 
                                 image={data.previous.featuredImage.node.localFile.childImageSharp.gatsbyImageData} 
                                 // alt={node.featuredImage.node.altText} 
                              />
                              
                           :
                              <Image 
                                 w="100%" 
                                 verticalAlign="bottom" 
                                 fallbackSrc="https://via.placeholder.com/337x190" 
                              />
                        }
                     </AspectRatio>
                     <Wrap>
                        
                        <WrapItem ml="1rem">
                           <Link as={GatsbyLink} to={data.previous.uri}>
                              {data.previous.title}
                           </Link>
                        </WrapItem>
                        <WrapItem>
                           {"<"}
                        </WrapItem>
                     </Wrap>
                     </>
                  }
               </Box>
               <Box bg="#fcfcfc" p="0.5rem" fontSize={"0.8rem"}>
                  {data.next && 
                     <>
                     <AspectRatio w="100%" ratio={16 / 9}>
                        {
                           data.next.featuredImage?
                              <GatsbyImage 
                                 image={data.next.featuredImage.node.localFile.childImageSharp.gatsbyImageData} 
                                 // alt={node.featuredImage.node.altText} 
                              />
                              
                           :
                              <Image 
                                 w="100%" 
                                 verticalAlign="bottom" 
                                 fallbackSrc="https://via.placeholder.com/337x190" 
                              />
                        }
                     </AspectRatio>
                     <Wrap>
                        <WrapItem>
                           <Link as={GatsbyLink} to={data.next.uri}>
                              {data.next.title}
                           </Link>
                        </WrapItem>
                        <Spacer />
                        <WrapItem ml="1rem">
                           {">"}
                        </WrapItem>
                     </Wrap>
                     </>
                  }
               </Box>
            </SimpleGrid >
            
            {/* {previousPostUrl && <Link as={GatsbyLink} to={previousPostUrl}>Previous page</Link> }
            {nextPostUrl && <Link as={GatsbyLink} to={nextPostUrl}>Next page</Link> } */}
      </Layout>
      </>
   )
}

export default BlogIndex;

export const postQuery = graphql`
   query BlogPostById(
      $id: String!,
      $previousPostId: String,
      $nextPostId: String,
   )
   {
      post: wpPost(id: { eq: $id }) {
         id
         excerpt
         content
         title
         date(formatString: "YYYY.MM.DD")
         modified(formatString: "YYYY.MM.DD")
         categories {
            nodes {
            slug
            name
            }
         }
         tags {
            nodes {
            name
            slug
            }
         }
         author {
            node {
            name
            slug
            }
         }
         kanren_YoutubeChannelVideoId {
            kanrenYoutubechannelids
            kanrenYoutubevideoids
         }
         featuredImage {
            node {
               localFile {
                  childImageSharp {
                     gatsbyImageData(layout: CONSTRAINED)
                  }
               }
            }
         }
         toc
      }
      previous: wpPost(id: { eq: $previousPostId }) {
         uri
         title
         featuredImage {
            node {
               localFile {
                  childImageSharp {
                     gatsbyImageData(layout: CONSTRAINED)
                  }
               }
            }
         }
      }
      next: wpPost(id: { eq: $nextPostId }) {
         uri
         title
         featuredImage {
            node {
               localFile {
                  childImageSharp {
                     gatsbyImageData(layout: CONSTRAINED)
                  }
               }
            }
         }
      }
      
   }
`